// 我的项目页
<template>
  <div class="sld_project_detail">
    <!-- <MemberTitle :memberTitle="L['我的项目']"></MemberTitle> -->
    <div class="container">
      <h3>{{L['我的项目']}}</h3>
      <div class="box2f flex-row">
        <span class="word23" :class="{active:tabIndex==0}" @click="changeTab(0)">全部</span>
        <span class="word23" :class="{active:tabIndex==1}" @click="changeTab(1)">未开始</span>
        <span class="word23" :class="{active:tabIndex==2}" @click="changeTab(2)">进行中</span>
        <span class="word23" :class="{active:tabIndex==3}" @click="changeTab(3)">已结束</span>
        <div class="box3 flex-col">
          <div class="layer13 flex-row">
            <el-input maxlength="25" v-model="selectValue" placeholder="请输入项目名称" @clear="getList" clearable />
            <span class="line1"></span>
            <i class="iconfont iconsousuo- label8" @click="getList"></i>
          </div>
        </div>
      </div>
      <div class="box5 flex-col" @click="createProject">
        <span class="word25">创建项目</span>
      </div>
      <div class="box10 flex-col">
        <div class="bd3 flex-row">
          <span class="word33">项目名称</span>
          <span class="word34">创建时间</span>
          <span class="info17">项目周期</span>
          <span class="word35">项目描述</span>
          <span class="word36">项目状态</span>
          <span class="info18">操作</span>
        </div>
      </div>
      <template v-if="projectList.length > 0">
        <template v-for="item in projectList" :key="item.id">
          <div class="box11 flex-row">
            <span v-if="item.projectName && item.projectName.length>13" class="word33"
              :title="item.projectName">{{item.projectName}}</span>
            <span v-else class="word33">{{item.projectName}}</span>
            <span class="word34">{{item.createTime}}</span>
            <span class="info17">{{item.startTime.split(' ')[0]}}-{{item.endTime.split(' ')[0]}}</span>
            <span v-if="item.description && item.description.length>15" class="word35"
              :title="item.description">{{item.description}}</span>
            <span v-else class="word35">{{item.description?item.description:'--'}}</span>
            <span class="word36">{{item.stateValue?item.stateValue:'--'}}</span>
            <span class="info18 flex-row">
              <div @click="info(item.projectId)">查看详情</div>
              <div @click="edit(item)" v-if="item.state == 1">编辑</div>
              <div @click="delet(item.projectId)" v-if="item.state != 2">删除</div>
            </span>
          </div>
        </template>
      </template>
      <SldCommonEmpty v-else totalWidth="1003" tip="暂无我的项目数据" />
    </div>
    <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current" :page-size="pageData.pageSize"
      layout="prev, pager, next, jumper" :total="pageData.total" :hide-on-single-page="false"
      class="flex_row_end_center" v-if="projectList.length > 0"></el-pagination>
    <ProjectModel ref="ProjectModel" @refreshProject="refreshProject"></ProjectModel>
  </div>
</template>

<script>
  import { reactive, ref, getCurrentInstance, onMounted } from "vue";
  import { useRouter } from "vue-router";
  import MemberTitle from '@/components/MemberTitle';
  import ProjectModel from '@/components/ProjectModel';
  import SldCommonEmpty from '@/components/SldCommonEmpty';
  import { ElMessage, ElMessageBox } from 'element-plus';
  export default {
    name: "project-list",
    components: {
      MemberTitle,
      ProjectModel,
      SldCommonEmpty
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const router = useRouter();
      const tabIndex = ref(0);
      const selectValue = ref('');
      const projectList = ref([]);
      const pageData = reactive({
        current: 1,
        pageSize: 10,
        total: 0
      });

      const changeTab = (index) => {  //切换导航栏
        if (tabIndex.value != index) {
          tabIndex.value = index
          pageData.current = 1;
          getList();
        }
      };

      const getList = () => { //获取项目数据
        let param = {
          current: pageData.current,
          pageSize: pageData.pageSize,
          name: selectValue.value,
          state: tabIndex.value == 0 ? '' : tabIndex.value
        }
        proxy.$get('v3/member/front/project/list', param)
          .then(res => {
            if (res.state == 200) {
              projectList.value = res.data.list;
              pageData.total = res.data.pagination.total;
            }
          })
      };

      const info = (id) => {  //查看详情
        router.push({
          path: `/member/project/detail`,
          query: {
            id: id
          }
        });
      };

      const edit = (item) => {
        proxy.$refs.ProjectModel.visibleFlag = true;
        proxy.$refs.ProjectModel.isEdit = true;

        proxy.$refs.ProjectModel.projectId = item.projectId;
        proxy.$refs.ProjectModel.form.data.projectName = item.projectName;
        proxy.$refs.ProjectModel.form.data.startTime = item.startTime;
        proxy.$refs.ProjectModel.form.data.endTime = item.endTime;
        proxy.$refs.ProjectModel.form.data.description = item.description;
        proxy.$refs.ProjectModel.time = [item.startTime, item.endTime]

        // proxy.$refs.ProjectModel.getInfo(id);
      };

      const delet = (id) => {
        ElMessageBox.confirm("确定删除项目?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          proxy
            .$post('v3/member/front/project/delete', { projectId: id })
            .then(res => {
              if (res.state == 200) {
                ElMessage.success('删除成功');
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            })
        })
      };

      const createProject = () => {
        proxy.$refs.ProjectModel.initData();
        proxy.$refs.ProjectModel.visibleFlag = true;
        proxy.$refs.ProjectModel.isEdit = false;
      };

      //页数改变
      const handleCurrentChange = e => {
        pageData.current = Math.floor(e);
        getList();
      };

      const refreshProject = () => {
        proxy.$refs.ProjectModel.visibleFlag = false;
        getList();
      };

      onMounted(() => {
        getList();
      })

      return {
        L,
        pageData,
        tabIndex,
        getList,
        changeTab,
        selectValue,
        info,
        edit,
        delet,
        projectList,
        createProject,
        handleCurrentChange,
        refreshProject,
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/orderProjectList.scss";
</style>
<style lang="scss">
  .sld_project_detail {
    .el-dialog {
      padding: 0;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
    }

    .layer13 {
      .el-input {
        display: flex;
        height: 30px;
      }

      .el-input__inner {
        width: 263px;
        height: 30px;
        border: none;
      }

      .el-input__clear {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .el-pagination {
      background-color: #FFFFFF;
      padding-right: 10px;
      padding-bottom: 10px;
    }
  }

  .is-right {
    .el-date-range-picker__header {
      .el-icon-arrow-right {
        margin: 8px 0 0;
      }
    }
  }
</style>